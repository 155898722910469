import React from "react";
import "./App.css";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { HomePage } from "./homepage/Homepage";
import { ParallaxProvider } from "react-scroll-parallax";
import createTheme from "@material-ui/core/styles/createTheme";
import { ThemeProvider as ThemeProviderMui } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { RestaurantMenu } from "./menu/restaurant_menu";

const GlobalStyle = createGlobalStyle`
  body{
    color: whitesmoke;
    font-family: Georgia, serif;
  }
`;

export const theme = {
  primaryColor: "#333",
  secondaryColor: "#2D4CBE",
  backgroundColor: "#4d4d4d",
  primaryText: "whitesmoke",
  secondaryText: "whitesmoke",
  largeFont: "2em",
  spacing: "1.2em",
  gradient: "linear-gradient(#333, #2D4CBE)",
  input: {
    color: "whitesmoke",
  },
};

const muiTheme = createTheme({
  palette: {
    type: "dark",
    primary: blue,
  },
});

function App() {
  return (
    <RestaurantMenu />
  );
}

export default App;
